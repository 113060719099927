@import "variables";

$gutter: 15px;
$padding-left: $gutter + 25px;
$padding-right: $padding-left + 10px;

.wrapper {
  position: relative;
  margin-left: $gutter;
}

.select {
  appearance: none;
  border: 1px solid $slate;
  border-radius: 20px;
  font-size: 12px;
  padding: 8px $padding-left;
  padding-right: $padding-right;
}

.icon,
.caret {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.icon {
  left: $gutter;
}

.caret {
  right: $gutter;
}
